import React      from "react";
import ReactDOM   from "react-dom";
import YouTube    from "react-youtube";

import "./styles.css";
import Logo       from "./components/Logo/Logo";
import { Helmet } from "react-helmet";

import fi96 from './components/favicon/favicon-96x96.png';
import fi16 from './components/favicon/favicon-16x16.png';

function App() {
    return (
        <div className="App">
            <Helmet>
                <html lang="ru"/>
                <title>Кормушка для птиц от мастерской центра Вверх Руки Оттуда</title>
                <link rel="icon" type="image/png" sizes="96x96" href={fi96}/>
                <link rel="icon" type="image/png" sizes="16x16" href={fi16}/>
                <meta name="theme-color" content="#ffffff"/>
            </Helmet>
            <div className="Header">
                {/*<Logo/>*/}
                <h1>Мастерская Руки Оттуда</h1>
            </div>
            <div className="Content">
                <p>
                    Привет 👋!<br/>
                    Мы — мастерская 🔨«Руки Оттуда»и мы сделали эту кормушку.<br/>
                    Посмотрите как ее правильно собрать 👇
                </p>
            </div>
            <YouTube
                videoId={"xdHhFbL3fUQ"} // defaults -> null
                className={"Video"} // defaults -> null
                containerClassName={"VideoContainer"} // defaults -> ''
            />
            <div className="Content">
                <p>
                    Наша мастерская — проект центра равных возможностей «Вверх» ⚡. Мы помогаем ребятам учиться,
                    развиваться и создаем комьюнити, на которое можно расчитывать в любой жизненной ситуации.
                    Спасибо, что вы поддерживаете нас 😍
                </p>
            </div>
            <YouTube
                videoId={"7DzkHj04YG4"} // defaults -> null
                className={"Video"} // defaults -> null
                containerClassName={"VideoContainer"} // defaults -> ''
            />

            <div className="Footer">Мастерская «Руки оттуда»</div>

        </div>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);
