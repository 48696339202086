import React     from 'react';
import logo     from './ruki-ottuda-logo.svg';
import logoVverh from './vverh-logo.svg';
import { cn }    from '@bem-react/classname';

const cnLogo = cn('Logo');

const Logo = ({type}) => {
    let svg;
    if (type === 'vverh') {
        svg = logoVverh;
    } else {
        svg = logo;
    }
    return <div className={cnLogo}>{svg}</div>;
};

export default Logo;